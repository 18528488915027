import styled from "styled-components";

export const BodyLoading = styled.div`
position:absolute;
top:50%;
left:47%;
transform: translate(-50%,-50%);



display: flex;
align-items: center;


span{
   margin-left:16px;
   color:#fff;
   margin-bottom:0;
   font-size:12px;
}

`