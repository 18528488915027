export const colors={
    PRYMARY:"#CCE7F5",
    BORDER_PRIMARY:"#AADEF9",
    BUTTON:"#464646",
    CARD_BUTTONS:"#0E0E0E",
    COLOR_TITLE:"#FFFFFF",
    COLOR_SUBTITLE:"#191919",
    COLOR_COMUM_TEXT:"#878787",
    COLOR_LINK_MENU:"#000000",
    BACKGROUND:"#F3F3F3",
    RED: "#E72424",
    GREEN:"#009d43;",
}